import React from "react"
import { graphql } from "gatsby"
import ConditionalLayout from "../components/ConditionalLayout"
import { theme } from "@singita/components"
import FullPageGallery from "../components/FullPageGallery"
import HelmetWrapper, { getJsonLd } from "gatsby-singita-theme/src/components/Helmet"
import { useSeo } from "gatsby-singita-theme/src/hooks"
import { LODGE_DATA_MAP_SUBPAGES } from "../common/constants"
import { TEMPLATE_TYPES } from "gatsby-singita-theme/src/common/constants"
import { NavbarContext } from "gatsby-singita-theme/src/context/NavbarContext"

const GalleryTemplate = props => {
  const {
    data: { lodge },
    location: { state },
  } = props
  const { isModal } = state || {}
  const seoProps = useSeo(lodge, LODGE_DATA_MAP_SUBPAGES)
  const jsonLd = getJsonLd(seoProps, TEMPLATE_TYPES.GALLERY)
  seoProps.title = `${seoProps.sdTitle} | Photo & Video Gallery | Singita`
  seoProps.description = `View Singita's life changing game drives, bush walks and holistic wellness treatments to soothe the soul at ${seoProps.sdTitle}`
  const [filterOffset, setFilterOffset] = React.useState(0)
  const { navbarHeight, setNavbarStyles } = React.useContext(NavbarContext)

  React.useLayoutEffect(() => {
    setNavbarStyles({ bg: theme.colors.bgLighter })
  }, [])

  React.useLayoutEffect(() => {
    if (isModal) {
      const elem = document.getElementById("large-modal-header")
      if (elem) {
        const dims = elem.getBoundingClientRect()
        setFilterOffset(dims.height)
      }
    } else {
      setFilterOffset(navbarHeight)
    }
  }, [isModal, navbarHeight])

  return (
    <>
      <HelmetWrapper {...seoProps} schemaJsonLd={jsonLd}/>

      <ConditionalLayout
        modalSize="large"
        modalTitle={lodge.fullTitle}
        bg={theme.colors.brandBeige}
        pageContext={props.pageContext}
      >
        {_ => (
          <FullPageGallery
            title={`${lodge.fullTitle} Gallery`}
            galleries={lodge.galleries}
            isModal={isModal}
            filterOffset={filterOffset}
          />
        )}
      </ConditionalLayout>
    </>
  )
}

export default GalleryTemplate

export const LODGE_GALLERY_QUERY = graphql`
  query lodgeGalleryQuery($slug: String!, $locale: String) {
    lodge: contentfulLodge(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      id
      fullTitle
      structuredData {
        headline
        image {
          fluid(maxWidth: 800, quality: 95) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      shortDescription {
        shortDescription
      }
      region {
        headline
        country
      }
      image {
        fluid(maxWidth: 800, quality: 95) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      galleries {
        __typename
        ...ContentContentAssetFields
      }
    }
  }
`
